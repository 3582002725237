import React from "react";
import {Link, graphql} from "gatsby";

import Bio from "../components/Bio";
import Layout from "../components/Layout";
import SEO from "../components/seo";

import styled from "styled-components";

import {SmallCaps, FakeSmallCaps} from "../utils/smallcaps";
import formatDate from "../utils/formatDate";

import {DiscussionEmbed} from "disqus-react";

const SupTitle = styled(SmallCaps)`
  border-bottom: 1px solid ${(props) => props.theme.colors.border};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > span,
  a {
    padding: 0.5em 0;
  }
`;

const Title = styled.h1`
  width: 100%;
  text-align: center;
  margin: 2.8rem 0;
`;

const Main = styled.div`
  max-width: ${(props) => props.theme.maxWidth}px;
  margin: 0 auto;
  padding: 1em;

  .custom-block.snippet .custom-block-body {
    font-style: italic;
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.2;
    margin-bottom: -1rem;
  }

  h2 {
    margin-top: 2em;
    font-size: 1.4rem;
  }

  h3 {
    margin-top: 2rem;
    font-size: 1.2rem;
  }

  .toc {
    @media (max-width: ${(props) => props.theme.maxWidth + 400}px) {
      display: none;
    }

    z-index: 10;
    position: sticky;
    top: 2em;
    font-size: 70%;
    height: 0px;

    ul {
      list-style: none;
      display: inline-block;
      width: calc((100vw - ${(props) => props.theme.maxWidth}px) / 2 - 4em);
    }
    li {
      padding-left: 0;
      margin-bottom: 0.3em;
      p {
        margin: 0;
        margin-bottom: 0.5em;
      }
    }
    a {
      border-bottom: none;

      &.is-active {
        font-weight: bold;
      }
    }

    > ul {
      padding-left: 0;
      transform: translateX(calc(-100% - 2em)) translateY(4em);

      ::before {
        content: "Table of contents";
        font-weight: bold;
        padding-bottom: 0.5em;
        display: inline-block;
      }
    }
  }
`;

const Nav = styled(SmallCaps)`
  margin-bottom: 2em;
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      margin: 0;
      padding: 0;
      a {
        display: inline-block;
        padding: 0.5em 0;
      }
      list-style-type: none;

      &.prev {
        flex-grow: 1;
      }

      &.next {
        flex-grow: 1;
        text-align: right;
        padding-left: 1em;
      }
    }
  }
`;

function observeTableOfContents() {
  let options = {
    root: document.querySelector(".blog-post"),
    rootMargin: "0px",
    threshold: 1.0,
  };

  let links = Array.from(document.querySelectorAll(".toc a"));
  let previousSection = null;
  let highlightFirstActive = () => {
    let firstVisibleLink = document.querySelector(".toc .is-visible");

    links.forEach((link) => link.classList.remove("is-active"));

    if (firstVisibleLink) {
      firstVisibleLink.classList.add("is-active");
    }

    if (!firstVisibleLink && previousSection) {
      document
        .querySelector(`.toc a[href$="#${previousSection}"]`)
        .classList.add("is-active");
    }
  };

  let handleObserver = (entries, observer) => {
    entries.forEach((entry) => {
      let href = `#${entry.target.getAttribute("id")}`;
      let link = links.find((l) => l.getAttribute("href").endsWith(href));

      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        link.classList.add("is-visible");
        previousSection = entry.target.getAttribute("id");
      } else {
        link.classList.remove("is-visible");
      }

      highlightFirstActive();
    });
  };

  let observer = new IntersectionObserver(handleObserver, options);
  let headings = Array.from(document.querySelectorAll(".toc a")).map((a) =>
    document.getElementById(a.href.split("#")[1])
  );

  headings.forEach((h) => observer.observe(h));
  this.observer = observer;
}

class BlogPostTemplate extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      try {
        observeTableOfContents.call(this);
      } catch (e) {}
    }, 2000);
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const {previous, next} = this.props.pageContext;
    const date = new Date(post.frontmatter.date);
    const {day, year, month} = formatDate(date);
    const disqusShortname = "gilles-castel-blog";
    const disqusConfig = {
      identifier: post.frontmatter.disqus,
      title: post.frontmatter.title,
    };

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <Main>
          <SupTitle>
            <Link to="/">← All articles</Link>
            <span>
              {month} <FakeSmallCaps>{day}</FakeSmallCaps>,{" "}
              <FakeSmallCaps>{year}</FakeSmallCaps> &bull;{" "}
              <FakeSmallCaps>{post.timeToRead}</FakeSmallCaps> Min Read
            </span>
          </SupTitle>
          <Title>
            {post.frontmatter.title.replace(/(\w+) (\w+)$/, "$1\u00A0$2")}
          </Title>
          <div dangerouslySetInnerHTML={{__html: post.html}} />
          <Bio />

          <Nav>
            <ul>
              {previous && (
                <li className="prev">
                  <Link to={`/post${previous.fields.slug}`} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                </li>
              )}
              {next && (
                <li className="next">
                  <Link to={`/post${next.fields.slug}`} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                </li>
              )}
            </ul>
          </Nav>
          <DiscussionEmbed shortname={disqusShortname} config={disqusConfig} />
        </Main>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 260)
      html
      timeToRead
      frontmatter {
        title
        date
      }
    }
  }
`;
